<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div style="line-height: 10px">
        <h4>Available</h4>
        <span class="text-muted">Empower Company</span>
      </div>
      <b-button
        variant="primary"
        class="rounded-pill"
        size="sm"
        v-b-modal.new-level
      >
        New Level
      </b-button>
    </div>
    <b-row>
      <b-col
        md="3"
        v-for="level in levels"
        :key="level.id"
        v-b-modal="'detail_' + level.id"
      >
        <b-card class="mt-2">
          <b-card-body align="center">
            <b-avatar :src="level.icon" size="50"></b-avatar>
            <b-card-text class="mt-2">
              <h4>{{ level.name }}</h4>
              <span>{{ level.users_count }} People Assigned</span>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-for="level in levels"
      :key="level.id"
      :id="'detail_' + level.id"
      title="Detail"
      size="md"
      hide-footer
    >
      <form @submit.prevent="form_update(level)">
        <b-form-group>
          <b-form-input
            v-model="level.name"
            type="text"
            placeholder="level Name"
          />
        </b-form-group>
        <div class="text-right mt-1">
          <b-button
            variant="outline-danger"
            class="mr-1"
            size="sm"
            @click="deleteD(level.id)"
          >
            Delete
          </b-button>
          <b-button type="submit" variant="primary" size="sm">
            Update
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="new-level" title="New Level" size="md" hide-footer>
      <form @submit.prevent="newLevelForm">
        <b-form-group label="Level Name">
          <b-form-input
            v-model="level_name"
            type="text"
            placeholder="Level Name"
          />
        </b-form-group>
        <div class="text-right mt-1">
          <b-button type="submit" variant="primary" size="sm">
            Create
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      levels: [],
      level_name: "",
    };
  },
  mounted() {
    this.callApi({
      method: "GET",
      url: "/job_levels/fetch",
      success: (response) => {
        this.levels = response.result;
      },
      error: (error) => {
        console.log(error);
      },
    });
  },
  methods: {
    deleteD(id) {
      this.callApi({
        method: "DELETE",
        url: "/teams/delete/" + this.hashid(id),
        success: (response) => {
          this.levels = this.levels.filter((level) => level.id != id);

          this.$bvModal.hide("detail_" + id);
        },
        error: (error) => {
          console.log(error);
        },
      });
    },

    newLevelForm() {
      this.callApi({
        method: "POST",
        url: "/job_levels/create",
        data: {
          name: this.level_name,
        },
        success: (response) => {
          this.levels.push(response.result);
          this.$bvModal.hide("new-level");
        },
        error: (error) => {
          console.log(error);
        },
      });
    },

    form_update(data) {
      this.callApi({
        method: "POST",
        url: `/job_levels/update/${this.hashid(data.id)}`,
        data: {
          name: data.name,
        },
        success: (response) => {
          this.levels.push(response.result);
          this.$bvModal.hide(`detail_${data.id}`);
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
};
</script>
